import { Stack, Title } from "@mantine/core";
import {
  IconBuildingBank,
  IconShield,
  IconUserUp,
  IconAdjustmentsHorizontal,
  IconUserPlus,
} from "@tabler/icons-react";
import SettingsGeneral from "./Settings.General";
import SettingsBilling from "./Settings.Billing";
import SettingsAccount from "./Settings.Account";
import SettingsRoles from "./Settings.Roles";
import { useTranslation } from "react-i18next";
import Tabs from "src/components/Tabs";
import SettingsInvites from "./Settings.Invites";
import Config from "../classes/application/Config";

const cfg = Config.getInstance();


const tabs = [
  {
    id: "general",
    title: "general",
    Icon: <IconAdjustmentsHorizontal />,
    content: <SettingsGeneral />,
  },
  {
    id: "roles",
    title: "roles",
    Icon: <IconUserUp />,
    content: <SettingsRoles />,
  },
  {
    id: "invites",
    title: "invites",
    Icon: <IconUserPlus />,
    content: <SettingsInvites />,
  },
  {
    id: "account",
    title: "account",
    Icon: <IconShield />,
    content: <SettingsAccount />,
  },
];

if(cfg.hasFeatureCredits()) {
  tabs.push(
  {
    id: "billing",
        title: "billing",
      Icon: <IconBuildingBank />,
      content: <SettingsBilling />,
  }
  );

}

export default function Settings() {
  const { t } = useTranslation();

  return (
    <Stack p="md">
      <Title p="md">{t("settings")}</Title>
      <Tabs tabs={tabs} />
    </Stack>
  );
}
