import iconMaps from "./iconMaps";
import Config from "../classes/application/Config";

const cfg = Config.getInstance();
let mainLinks = [
  {
    to: "/app",
    icon: iconMaps.company,
    label: "my_company",
  },
  {
    label: "employees",
    to: `/app/section/employees`,
  },
  {
    id:"job_offers",
    label: "job_offers",
    to: `/app/section/jobs`,
  },
  {

    id:"credits",
    label: "credits",
    to: "/app/credits",
  },
  {
    label: "matches",
    to: `/app/section/matches`,
  },
  {
    to: "",
    icon: iconMaps.proposals,
    isOpen: true,
    label: "activity",
    links: [
      {
        label: "proposals_sent",
        to: `/app/section/proposals_sent`,
      },
      {
        label: "proposals_received",
        to: `/app/section/proposals_received`,
      },
      {
        label: "applications_sent",
        to: `/app/section/applications_sent`,
      },
      {
        label: "applications_received",
        to: `/app/section/applications_received`,
      },
    ],
  },
  {
    to: "/app/view/job",
    icon: iconMaps.searchJob,
    label: "find_jobs",
    classes: "tour_find_job",
  },
  {
    to: "/app/view/employee",
    icon: iconMaps.searchEmployee,
    label: "find_employees",
    classes: "tour_find_employee",
  },
  {
    label: "invites_request",
    to: "/app/section/invites",
    icon: iconMaps.inviteRequest,
  },
  {
    label: "invite_company",
    to: "/app/invite",
    icon: iconMaps.history,
    disabled: true,
  },
];

export const altLinks = [
  {
    to: "/app/support",
    icon: iconMaps.support,
    label: "support",
  },
  {
    to: "/app/settings",
    icon: iconMaps.settings,
    label: "settings",
  },
];

mainLinks = mainLinks.filter(link => {
  if(link.id==="credits") {
    return cfg.hasFeatureCredits();
  }

  return true;
});
export { mainLinks };
